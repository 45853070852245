<template>
  <div>
    <CaseOverview :dataOverview="dataOverview"></CaseOverview>
    <div class="tab">
      <div @click="dataRanTab = 0" :class="dataRanTab ? '' : 'current'">
        <van-icon name="chart-trending-o" />
        数据分布
      </div>
      <div @click="dataRanTab = 1" :class="dataRanTab ? 'current' : ''">
        <van-icon name="bar-chart-o" />
        成绩排名
      </div>
    </div>

    <div v-if="notingData && !dataRanTab" class="bg_color">
      <van-empty
        description="暂无数据"
        image-size="80"
        :image="require('@/assets/images/kong.png')"
      />
    </div>
    <div class="charts" v-show="!dataRanTab">
      <div class="No1" v-if="ClassReviewStr.length">
        <Dis :title="'各班级评论人数分布'" :data="ClassReviewStr"></Dis>
      </div>
      <div class="No2" v-if="ClassAvgTime.length">
        <Dis :title="'各班级人均用时(秒)'" :data="ClassAvgTime"></Dis>
      </div>
      <div v-if="ClassCase.length">
        <Dis :title="'各班级学案分布'" :data="ClassCase"></Dis>
      </div>
      <div v-if="ClassDoubt.length">
        <Dis :title="'各班级题目疑问人数分布'" :data="ClassDoubt"></Dis>
      </div>
      <div v-if="ClassSub.length">
        <Dis :title="'各班级提交人数分布'" :data="ClassSub"></Dis>
      </div>
      <div v-if="ClassAwaitSub.length">
        <Dis :title="'各班级待提交人数分布'" :data="ClassAwaitSub"></Dis>
      </div>
      <div v-if="ClassReview.length">
        <Dis :title="'各班级批阅人数分布'" :data="ClassReview"></Dis>
      </div>
      <div v-if="ClassAwaitReview.length">
        <Dis :title="'各班级待批阅人数分布'" :data="ClassAwaitReview"></Dis>
      </div>
    </div>
    <div v-show="dataRanTab">
      <Ran :tableData="studentRanList" @changeRanType="changeRanType"></Ran>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, watch } from "vue";
import { useRoute } from "vue-router";
import CaseOverview from "../components/CaseOverview.vue";
import Ran from "@/views/teacher/components/ResultsRanking.vue";
import Dis from "@/views/chart/DataDis.vue";
import {
  apiGetOverviewOfAll,
  apiGetEchartsData,
  apiGetStudentRank,
} from "@/service/teacher1.4.js";

const route = useRoute();

onBeforeMount(async () => {
  await getChatData();
  await getOverViewData();
  await getStudentRan();
});

let dataRanTab = ref(0);

let dataOverview = ref([]);

// 获取学案数据概览
const getOverViewData = async (start_time, end_time) => {
  let start = new Date(new Date(new Date().toLocaleDateString()).getTime());
  let end = new Date(
    new Date(new Date().toLocaleDateString()).getTime() +
      24 * 60 * 60 * 1000 -
      1
  );

  let res = await apiGetOverviewOfAll({
    start_time: start_time ? start_time : start.getTime() / 1000,
    end_time: end_time ? end_time : Math.floor(end.getTime() / 1000),
  });
  if (res) {
    dataOverview.value.push(
      {
        num: res.data.reviewstrNum,
        pNum: res.data.caseNum,
        title: "评语/学案数",
      },
      { num: res.data.qaNum, pNum: res.data.scoureNum, title: "题目/资料数" },
      { num: res.data.qaNum, title: "题目疑问数" },
      {
        num: res.data.submitPNum,
        pNum: res.data.pendingSubmitPNum,
        title: "提交/待提交人数",
      },
      {
        num: res.data.reviewPNum,
        pNum: res.data.pendingReviewPNum,
        title: "批阅/待批阅人数",
      },
      {
        num: res.data.reviewed,
        pNum: res.data.pendingReview,
        title: "批阅/待批阅次数",
      },
      {
        num: res.data.submitedNum,
        pNum: res.data.pendingSubmitNum,
        title: "批阅/待批阅次数",
      },
      {
        num: (+res.data.avgPTakeTime / 60).toFixed(2),
        title: "人均用时（分钟）",
      },
      {
        num: (+res.data.sumTakeTime / 60).toFixed(2),
        title: "总用时（分钟）",
      }
    );
  }
};

// 获取图表数据
let ClassReviewStr = ref([]);
let ClassAvgTime = ref([]);
let ClassCase = ref([]);
let ClassDoubt = ref([]);
let ClassSub = ref([]);
let ClassAwaitSub = ref([]);
let ClassReview = ref([]);
let ClassAwaitReview = ref([]);
let notingData = ref(true);
const getChatData = async (start_time, end_time) => {
  let start = new Date(new Date(new Date().toLocaleDateString()).getTime());
  let end = new Date(
    new Date(new Date().toLocaleDateString()).getTime() +
      24 * 60 * 60 * 1000 -
      1
  );
  let res = await apiGetEchartsData({
    start_time: start_time ? start_time : start.getTime() / 1000,
    end_time: end_time ? end_time : Math.floor(end.getTime() / 1000),
  });
  if (res) {
    ClassReviewStr.value = res.data.reviewstr_class_distributed;
    ClassAvgTime.value = res.data.avgtime_class_distributed;
    ClassCase.value = res.data.case_class_distributed;
    ClassDoubt.value = res.data.issue_class_distributed;
    ClassSub.value = res.data.submit_class_distributed;
    ClassAwaitSub.value = res.data.unsubmit_class_distributed;
    ClassReview.value = res.data.review_class_distributed;
    ClassAwaitReview.value = res.data.unreview_class_distributed;

    if (
      ClassReviewStr.value.length ||
      ClassAvgTime.value.length ||
      ClassCase.value.length ||
      ClassDoubt.value.length ||
      ClassSub.value.length ||
      ClassAwaitSub.value.length ||
      ClassReview.value.length ||
      ClassAwaitReview.value.length
    ) {
      notingData.value = false;
    } else {
      notingData.value = true;
    }
  }
};

// 获取排名
let studentRanList = ref([]);
const getStudentRan = async (type) => {
  let res = await apiGetStudentRank({
    order_by: type ? type : "correctRate",
  });
  if (res) {
    studentRanList.value = res.data.slice(0, 100);
  }
};

const changeRanType = async (val) => {
  studentRanList.value = [];
  await getStudentRan(val);
};

// 监听路由变化（切花时间tab）
watch(
  () => route.query,
  async () => {
    // 日
    if (route.query.date == "0") {
      dataOverview.value = [];
      getOverViewData();
      getChatData();
    }
    // 周
    if (route.query.date == "1") {
      let date = new Date();
      //本周第一天
      let startTime = new Date(
        new Date(date).getTime() -
          3600 *
            1000 *
            24 *
            (new Date(date).getDay() == 0 ? 6 : new Date(date).getDay() - 1)
      );
      //本周最后一天
      let endTime = new Date(
        new Date(date).getTime() +
          3600 *
            1000 *
            24 *
            (new Date(date).getDay() == 0 ? 0 : 7 - new Date(date).getDay(date))
      );
      dataOverview.value = [];
      await getChatData(
        Math.floor(startTime.getTime() / 1000),
        Math.floor(endTime.getTime() / 1000)
      );
      await getOverViewData(
        Math.floor(startTime.getTime() / 1000),
        Math.floor(endTime.getTime() / 1000)
      );
    }
    // 月
    if (route.query.date == "2") {
      dataOverview.value = [];
      await getChatData(
        new Date(getFirstDay()).getTime() / 1000,
        new Date(getLastDay()).getTime() / 1000
      );
      await getOverViewData(
        new Date(getFirstDay()).getTime() / 1000,
        new Date(getLastDay()).getTime() / 1000
      );
    }
    // 年
    if (route.query.date == "3") {
      dataOverview.value = [];
      await getChatData(
        new Date(new Date().getFullYear() + " 01-01").getTime() / 1000,
        new Date(new Date().getFullYear() + " 12-31").getTime() / 1000
      );
      await getOverViewData(
        new Date(new Date().getFullYear() + " 01-01").getTime() / 1000,
        new Date(new Date().getFullYear() + " 12-31").getTime() / 1000
      );
    }
  }
);

// 获取本月第一天
function getFirstDay() {
  var y = new Date().getFullYear(); //获取年份
  var m = new Date().getMonth() + 1; //获取月份
  var d = "01";
  m = m < 10 ? "0" + m : m; //月份补 0
  return [y, m, d].join("-");
}
// 获取本月最后一天
function getLastDay() {
  var y = new Date().getFullYear(); //获取年份
  var m = new Date().getMonth() + 1; //获取月份
  var d = new Date(y, m, 0).getDate(); //获取当月最后一日
  m = m < 10 ? "0" + m : m; //月份补 0
  d = d < 10 ? "0" + d : d; //日数补 0
  return [y, m, d].join("-");
}
</script>

<style lang="less" scoped>
@import "@/common/style/bg_color.less";
.charts {
  display: flex;
  flex-wrap: wrap;
  .No1,
  .No2 {
    // padding-top: 10px;
    // background-color: rgb(208, 245, 220);
  }
}
.charts > div {
  width: 50%;
  padding-bottom: 10px;
  // margin-bottom: 30px;
}
.tab {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  div:nth-child(1) {
    border-radius: 5px 0 0 5px;
  }
  div:nth-child(2) {
    border-radius: 0 5px 5px 0;
  }
  div {
    margin: 0;
    border: 1px solid #18a4e0;
    width: 50%;
    text-align: center;
  }
  .current {
    background-color: rgb(101, 130, 235);
    border: none;
    color: #fff;
  }
}

@media screen and (orientation: portrait) and (max-device-width: 420px) {
  .charts {
    display: block;
  }
  .charts > div {
    width: 100%;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}
</style>
