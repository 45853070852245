<template>
  <div id="dataDis" ref="dataDis"></div>
</template>

<script setup>
import { onMounted, ref, defineProps } from "vue";
import * as echarts from "echarts/core";
import {
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  DatasetComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  CanvasRenderer,
  TitleComponent,
]);

let props = defineProps(["title", "data"]);
// 表格实例
let dataDis = ref(null);
onMounted(() => {
  setTimeout(async () => {
    let obj = { title: ["product"], data: [] };
    let type = [];
    if (props.data.length) {
      let arr = [];
      props.data.forEach((item) => {
        obj.title.push(item.className);
        arr.push({ [item.className]: item.value });
        type.push({ type: "bar" });
      });

      let NewObj = { product: "" };
      arr.forEach((item) => {
        for (let key in item) {
          NewObj[key] = item[key];
        }
      });
      obj.data.push(NewObj);
    }

    var chartDom = dataDis.value;
    var myChart = echarts.init(chartDom);
    var option;
    var app = {};

    option = {
      title: {
        text: props.title,
        left: "center",
        fontSize: "12",
      },
      legend: { y: "bottom", x: "center" },
      tooltip: {},
      dataset: {
        dimensions: obj.title,
        source: obj.data,
      },
      xAxis: { type: "category" },
      yAxis: {},
      // Declare several bar series, each will be mapped
      // to a column of dataset.source by default.
      series: type,
    };

    option && myChart.setOption(option);
  }, 1000);
});
</script>

<style lang="less" scoped>
#dataDis {
  width: 100%;
  height: 200px;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
}
</style>
